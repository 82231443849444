<template>
  <cks-combined-route @active="refreshActive">
    <div class="reception-news-center-detail">
      <div class="content w">
        <div class="header">
          <div class="title">{{ detailData.title }}</div>
          <div class="date">{{ getTime(detailData.lastPubDate) }}</div>
        </div>
        <div class="cont" v-html="detailData.detail">
        </div>
        <div class="back curr" @click="back">
          <img src="@/assets/images/news-image/back.png" alt="">
        </div>
      </div>
    </div>
  </cks-combined-route>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import apis from '@/request/apis';
import { getTime } from '@/utils';

export default {
  props: ['id', 'isIndex'],
  setup(props) {
    const router = useRouter();
    const data = reactive({
      detailData: {},
      getTime,
    });

    const back = () => {
      router.push({
        name: props.isIndex === 'Y' ? 'index' : 'receptionNewsCenter',
      });
    };

    const regex = new RegExp('<img', 'gi');
    async function loadDetail() {
      const res = await apis.receptionNewsCenter.getDetail({ id: props.id });
      res.detail = res.detail.replace(regex, '<img style="max-width: 100%; height: auto;display: block;margin: 0 auto;"');
      data.detailData = res;
    }

    onMounted(() => {
      document.querySelector('#app').scrollTop = 0;
    });

    loadDetail();

    return {
      ...toRefs(data),
      back,
    };
  },
};
</script>

<style lang="scss" scoped>
.reception-news-center-detail {
  padding-top: 5.208vw;
  .img-box {
    > img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .bgc {
    width: 100%;
    height: 4.792vw;
    background-color: #fff;
  }

  .content {
    padding: 3.49vw 12.604vw 0vw;
    .header {
      margin-bottom: 1.979vw;
      .title {
        font-size: 2.656vw;
        font-weight: 500;
        color: #31499F;
        line-height: 3.125vw;
      }
      .date {
        width: 33%;
        font-size: 1.719vw;
        font-weight: 100;
        color: #000000;
      }
    }
    .cont {
      font-size: 1.146vw;
      font-weight: 400;
      color: #000000;
      line-height: 1.771vw;
      text-indent: 2em;
    }
    .back {
      float: right;
      width: 5.625vw;
      height: 2.5vw;
      margin-top: 2.188vw;
      margin-bottom: 3.438vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .reception-news-center-detail {
    padding-top: 5.208vw;
    .img-box {
      > img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .bgc {
      width: 100%;
      height: 4.792vw;
      background-color: #fff;
    }

    .content {
      padding: 3.49vw 12.604vw 0vw;
      .header {
        margin-bottom: 1.979vw;
        .title {
          font-size: 2.656vw;
          font-weight: 500;
          color: #31499F;
          line-height: 4.125vw;
        }
        .date {
          width: 33%;
          font-size: 1.719vw;
          font-weight: 100;
          color: #000000;
        }
      }
      .cont {
        font-size: 1.867vw;
        font-weight: 400;
        color: #000000;
        line-height: 4.267vw;
        text-indent: 3.733vw;
      }
      .back {
        float: right;
        width: 12vw;
        height: 5vw;
        margin-top: 2.188vw;
        margin-bottom: 3.438vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
